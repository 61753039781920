// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// headings
h1,
h2 {
	margin-bottom: 30px;
}

// paragraph
p {
	min-height: 1rem;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// container
.container-fluid {
	max-width: 1920px;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 0;

	.card-body {
		padding: 15px 0 0 0;

		.card-caption {
			.card-title {
				font-size: $h4-font-size;
			}

			.card-subtitle {
				font-size: $h5-font-size;
			}
		}
	}
}

// default-tile-card
.default-tile-card {
	border: none;
	border-radius: 0;

	.card-img-overlay {
		border-radius: 0;
		background: rgba($black, 0.2);

		.card-caption {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			text-align: center;

			.card-title {
				margin-bottom: 0;
				font-size: 40px;
				text-transform: uppercase;
				font-family: $font-family-tertiary;

				&:hover {
					color: inherit;
				}
			}

			.card-subtitle,
			.card-description {
				height: 0;
				opacity: 0;
				visibility: hidden;
				transition: 0.5s;
			}

			&:hover {
				.card-subtitle {
					margin-top: 0.25rem;
				}

				.card-subtitle,
				.card-description {
					height: auto;
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

// heading-large
.heading-large {
	font-size: 40px;

	@include media-breakpoint-up(xl) {
		font-size: 60px;
	}
}

// heading-mega-large
.heading-mega-large {
	font-size: 60px;

	@include media-breakpoint-up(xl) {
		font-size: 80px;
	}
}

// text-large
.text-large {
	font-size: 20px;
	font-weight: 100;
}

// link-large
.link-large {
	font-size: 20px;

	@include media-breakpoint-up(xl) {
		font-size: 24px;
	}

	font-family: $font-family-secondary;
}
