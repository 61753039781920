// footer
.footer {

	// footer-outro
	.footer-outro {

		// logo
		.logo {
			max-width: 160px;
			margin-bottom: 6vh;
		}

		// footer-link
		.footer-link {
			margin: 6vh 0 30px 0;

			ul {
				flex-direction: column;

				li {
					font-size: 24px;
					font-family: $font-family-secondary;
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			margin: 30px 0 6vh 0;

			ul {
				li {
					margin-right: 15px;
					font-size: 24px;

					a {
						color: $white !important;

						&:hover {
							color: $gold !important;
						}
					}
				}
			}
		}

	}

	// footer-copyright
	.footer-copyright {
		padding: 30px 0;

		// footer-text
		.footer-text {
			ul {
				display: flex;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(xs) {
					flex-direction: column;
				}

				li {
					font-size: 14px;

					@include media-breakpoint-up(sm) {
						+li {
							&::before {
								content: "-";
								margin: 0 10px;
							}
						}
					}

					a {
						color: $black;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
