// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	margin-bottom: 6vh;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 50%;
		max-height: 300px;
		background-color: $green;
	}

	.image-container {
		margin-bottom: 140px;
		padding-left: 0;
		padding-right: 0;

		@include media-breakpoint-up(sm) {
			max-width: 510px;
		}

		@include media-breakpoint-up(md) {
			max-width: 690px;
			margin-bottom: 100px;
		}

		@include media-breakpoint-up(lg) {
			max-width: 930px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 1110px;
		}

		.container-holder {
			position: relative;
			flex-wrap: nowrap;
			align-items: flex-end;
			margin: 0;

			.column {
				flex: 1 1 auto;
				margin-bottom: 0;
				padding: 0 !important;

				&.one {
					position: relative;

					@include media-breakpoint-up(md) {
						max-width: 90%;
					}

					@include media-breakpoint-up(lg) {
						max-width: 80%;
					}

					@include media-breakpoint-up(xl) {
						max-width: 930px;
					}
				}

				&.two {
					max-width: 220px;

					@include media-breakpoint-down(lg) {
						position: absolute;
						right: 0;
						bottom: -100px;
					}

					@include media-breakpoint-down(sm) {
						right: 30px;
					}

					@include media-breakpoint-up(sm) {
						max-width: 40%;
					}

					@include media-breakpoint-up(xl) {
						margin: 0 0 -180px -265px;
						max-width: 445px;
					}
				}
			}
		}

		.image-holder {
			position: relative;

			.image-holder-caption {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgba($black, 0.2);

				h3 {
					margin-bottom: 0;
					color: $white;
					@include font-size($h2-font-size);
					text-transform: uppercase;
					font-family: $font-family-tertiary;

					@include media-breakpoint-up(xl) {
						font-size: 100px;
					}
				}
			}
		}
	}

	.text-container {
		.container-holder {
			.column {
				&.one {
					@include media-breakpoint-up(md) {
						@include make-col(6);
					}

					padding-right: 15px !important;
					align-self: center
				}

				&.two {
					@include media-breakpoint-up(md) {
						@include make-col(5);
						@include make-col-offset(1);
					}

					padding-left: 15px !important;
				}
			}
		}
	}
}

// intro-collection-section
// =========================================================================
.intro-collection-section {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 50%;
		background-color: $green;
	}

	// collection
	.collection {
		.card {
			@extend .default-tile-card;
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	position: relative;
	padding-top: 0 !important;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 180px;
		background-color: $white;
	}

	.image-container {
		margin-bottom: 140px;
		padding-left: 0;
		padding-right: 0;

		@include media-breakpoint-up(sm) {
			max-width: 510px;
		}

		@include media-breakpoint-up(md) {
			max-width: 690px;
			margin-bottom: 100px;
		}

		@include media-breakpoint-up(lg) {
			max-width: 930px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 1110px;
		}

		.container-holder {
			position: relative;
			flex-wrap: nowrap;
			align-items: flex-end;
			margin: 0;

			.column {
				flex: 1 1 auto;
				margin-bottom: 0;
				padding: 0 !important;
			}
		}
	}

	&:not(.inverse) {
		.image-container {
			.container-holder {
				.column {
					&.one {
						position: relative;

						@include media-breakpoint-up(md) {
							max-width: 90%;
						}

						@include media-breakpoint-up(lg) {
							max-width: 80%;
						}

						@include media-breakpoint-up(xl) {
							max-width: 930px;
						}
					}

					&.two {
						max-width: 220px;

						@include media-breakpoint-down(lg) {
							position: absolute;
							right: 0;
							bottom: -100px;
						}

						@include media-breakpoint-down(sm) {
							right: 30px;
						}

						@include media-breakpoint-up(sm) {
							max-width: 40%;
						}

						@include media-breakpoint-up(xl) {
							margin: 0 0 -180px -265px;
							max-width: 445px;
						}
					}
				}
			}
		}

		.text-container {
			.container-holder {
				.column {
					&.one {
						@include media-breakpoint-up(md) {
							@include make-col(6);
						}

						padding-right: 15px !important;
						align-self: center
					}

					&.two {
						@include media-breakpoint-up(md) {
							@include make-col(5);
							@include make-col-offset(1);
						}

						padding-left: 15px !important;
					}
				}
			}
		}
	}

	&.inverse {
		.image-container {
			.container-holder {
				justify-content: flex-end;

				.column {
					&.one {
						z-index: 1;
						max-width: 220px;

						@include media-breakpoint-down(lg) {
							position: absolute;
							left: 0;
							bottom: -100px;
						}

						@include media-breakpoint-down(sm) {
							left: 30px;
						}

						@include media-breakpoint-up(sm) {
							max-width: 40%;
						}

						@include media-breakpoint-up(xl) {
							margin: 0 -265px -180px 0;
							max-width: 445px;
						}
					}

					&.two {
						position: relative;

						@include media-breakpoint-up(md) {
							max-width: 90%;
						}

						@include media-breakpoint-up(lg) {
							max-width: 80%;
						}

						@include media-breakpoint-up(xl) {
							max-width: 930px;
						}
					}
				}
			}
		}

		.text-container {
			.container-holder {
				.column {
					&.one {
						@include media-breakpoint-up(md) {
							@include make-col(5);
						}

						padding-right: 15px !important;
						text-align: right;
						align-self: center
					}

					&.two {
						@include media-breakpoint-up(md) {
							@include make-col(6);
							@include make-col-offset(1);
						}

						padding-left: 15px !important;
					}
				}
			}
		}
	}
}

// tiles-collection-section
// =========================================================================
.tiles-collection-section {
	background-color: $green;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-tile-card;
					}
				}
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				.item {
					margin-bottom: 6vh;

					.card {
						@extend .default-card;
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
