// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 100vh;
	min-height: 300px;
	max-height: 600px;
}

.eyecatcher {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 50%;
		background-color: $green;
	}

	.owl-dots,
	.owl-nav {
		display: none;
	}
}
