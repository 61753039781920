// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #323a2b;
$gold: #b69e75;

$white: #fff;
$black: #000;

// theme-colors
$primary: $green;
$secondary: $gold;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $gold;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;300;400;700&family=Noto+Serif:wght@400;700&family=Roboto+Condensed&display=swap');
$font-family-primary: 'Be Vietnam Pro', sans-serif;
$font-family-secondary: 'Noto Serif', serif;
$font-family-tertiary: 'Roboto Condensed', sans-serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$font-weight-base: 300;
$line-height-base: 1.35;

$h1-font-size: $font-size-base * 3.75; // 60px
$h2-font-size: $font-size-base * 2.5; // 40px
$h3-font-size: $font-size-base * 2.25; // 36px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.125; // 18px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $green;

// contextual
$light: $gold;
$dark: $green;
$text-light: $white;
$text-dark: $white;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
