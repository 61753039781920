// header
.header {
	&.sticky {
		.header-top {
			z-index: 998;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			box-shadow: $shadow;
		}
	}

	.container-fluid {
		.container-holder {
			>.column {
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .align-items-center;
				@extend .navbar-expand-xl;
			}
		}
	}

	.header-top {
		padding: 10px 0 15px 0;
		background-color: $white;

		@include media-breakpoint-up(xl) {
			padding: 20px 15px;
		}

		// top-menu
		.top-menu {
			@include media-breakpoint-down(lg) {
				display: block !important;
				order: 4;
				flex-basis: auto;
				margin-top: 4px;
			}

			.navbar-nav {
				@include media-breakpoint-down(lg) {
					flex-direction: row !important;
					justify-content: flex-end;
				}

				margin-right: auto;
				align-items: center;

				li {
					font-size: 14px;
					line-height: 24px;
					text-transform: uppercase;

					@include media-breakpoint-up(xl) {
						font-weight: 700;
					}

					+li {
						margin-left: 15px;
					}

					&:first-of-type {
						a {
							&::before {
								content: '\f015';
								font-family: $font-awesome;
								font-size: 16px;
								font-weight: 700;
							}

							.menu-title {
								display: none;
							}
						}

						@include media-breakpoint-down(lg) {
							display: none;
						}

					}

					a {
						padding: 0 !important;
						color: $green;
						text-decoration: none;
					}

					&:hover,
					&.active {
						a {
							color: $gold;
						}
					}
				}
			}
		}
	}

	.header-bottom {
		padding: 50px 0;
		background-color: $green;
		transition: 0.15s;

		// wysiwyg
		.wysiwyg {
			color: $white;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $gold;
			}

			a {
				color: $white;
				text-decoration: underline;

				&:hover {
					color: $gold;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(lg) {
			order: 3;
			width: 21px;
			margin-right: auto;
		}

		.navbar-toggler {
			margin: 0;
			padding: 0;
			border: none;
			color: $gold;
			font-size: 24px;
			transition: color 0.5s;

			i {
				font-weight: 400;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 110px;

		@include media-breakpoint-up(md) {
			width: 160px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}

		img[src=""] {
			display: none;
		}
	}

	// main-menu
	.main-menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-top: 15px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $green;
						font-size: 16px;

						&:hover {
							color: $gold;
						}

						&.dropdown-toggle {
							&::after {
								content: "\f107";
								font-family: $font-awesome;
								border: none;
								margin-left: 5px;
								vertical-align: middle;
							}
						}
					}

					&.active {
						>.nav-link {
							color: $gold;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $green;

								&:hover {
									color: $gold;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $gold;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				margin-right: 15px;

				>.nav-item {
					margin-left: 5px;
					font-size: 14px;
					font-weight: 400;
					text-transform: uppercase;

					>.nav-link {
						display: flex;
						color: $green;

						&:hover {
							color: $gold;
						}
					}

					&.active {
						>.nav-link {
							color: $gold;
						}
					}
				}

				.nav-item {
					.dropdown-toggle {
						&::after {
							content: '\f107';
							font-family: $font-awesome;
							border: none;
							margin-left: 5px;
							vertical-align: middle;
						}
					}

					.dropdown-menu {
						margin-top: 0;
						padding: 10px;
						border-radius: 0;
						border-color: $green;

						&::before {
							border-bottom-color: $green;
							border-bottom-width: 9px;
						}

						.nav-item {
							font-size: 14px;
							line-height: 1.2;
							text-transform: none;
							white-space: nowrap;

							.nav-link {
								color: $green;

								&:hover {
									color: $gold;
								}
							}

							&.active {
								.nav-link {
									color: $gold;
								}
							}
						}
					}
				}
			}
		}
	}

	// socials
	div.socials {
		display: none;

		@include media-breakpoint-down(lg) {
			display: block;
			order: 1;
			width: calc(100% - 53px);
			margin-bottom: 5px;
		}

		ul {
			@include media-breakpoint-down(lg) {
				justify-content: flex-end;
			}

			li {
				margin-right: 15px;
				font-size: 20px;

				a {
					color: $green;

					&:hover {
						color: $gold;
					}

					.list-item-title {
						display: none;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		@include media-breakpoint-down(lg) {
			order: 2;
			width: 53px;
			margin-bottom: 5px;
		}
	}
}

&.home {
	.header {
		.header-bottom {
			.container-two-columns {
				.container-holder {
					.column {
						&.one {
							padding-right: 15px !important;
							@include make-col(5);

							@include media-breakpoint-up(sm) {
								@include make-col(4);
							}
						}

						&.two {
							align-self: flex-end;
							padding-left: 15px !important;
							@include make-col(7);

							@include media-breakpoint-up(sm) {
								@include make-col(8);
							}
						}
					}
				}
			}
		}
	}
}

&:not(.home) {
	.header {
		.header-bottom {
			.container-two-columns {

				.container-holder {
					.column {
						&.one {
							flex: 0;
							max-width: unset;
							padding-right: 15px !important;
						}

						&.two {
							flex: 1;
							max-width: unset;
							align-self: flex-end;
							padding-left: 15px !important;

							@include media-breakpoint-up(md) {
								margin-left: 15%;
							}
						}
					}
				}
			}
		}
	}
}
